import type { OnChanges, SimpleChanges } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import type { SeoNavbarLink } from '@freelancer/datastore/collections';
import { SeoNavbarParent } from '@freelancer/datastore/collections';
import { Location } from '@freelancer/location';
import { CalloutPlacement, CalloutSize } from '@freelancer/ui/callout';
import { IconColor, IconSize } from '@freelancer/ui/icon';
import type { QueryParams } from '@freelancer/ui/link';
import { LinkColor } from '@freelancer/ui/link';
import { FontColor, FontWeight } from '@freelancer/ui/text';

@Component({
  selector: 'app-mega-menu',
  template: `
    <fl-callout
      [edgeToEdge]="true"
      [placement]="CalloutPlacement.BOTTOM_LEFT"
      [hideCloseButton]="true"
      [hover]="true"
      [mobileFullscreen]="true"
      (calloutOpen)="openCalloutHandler()"
      (calloutClose)="closeCalloutHandler()"
      [mobileHeaderTitle]="treeData.name"
      [crawlable]="crawlable"
    >
      <fl-callout-trigger>
        <a
          flTrackingSection="SeoNavBarMegaMenu"
          flTrackingLabel="SeoNavBarMegaMenuRoot"
          [href]="treeData.relativeUrl"
          (click)="handleRootLink()"
        >
          <div
            (mouseenter)="openCalloutHandler()"
            (mouseleave)="closeCalloutHandler()"
          >
            <fl-text [color]="FontColor.LIGHT">
              {{ treeData.name }}
            </fl-text>
          </div>
        </a>
      </fl-callout-trigger>

      <fl-callout-content>
        <div
          class="Container"
          flTrackingSection="SeoNavBarMegaMenu"
          flTrackingLabel="SeoNavBarMegaMenuContainer"
          tabindex="-1"
          (mouseenter)="openCalloutHandler()"
          (click)="onClick($event)"
        >
          <div
            *ngFor="
              let column of columns;
              let depth = index;
              trackBy: trackByDepth
            "
            class="Container-innerContainer"
            [ngClass]="{
              'Container-border':
                lastKnowActiveNode &&
                (depth <= lastKnowActiveNode.depth ||
                  (depth == lastKnowActiveNode.depth + 1 &&
                    lastKnowActiveNode.nodes &&
                    lastKnowActiveNode.nodes.length > 0)) &&
                depth > 0,
              MobileNextColumn:
                (!lastKnowActiveNode && depth > 0) ||
                lastKnowActiveNode?.depth + 2 <= depth,
              MobileCurrColumn:
                (!lastKnowActiveNode && depth === 0) ||
                (lastKnowActiveNode && lastKnowActiveNode?.depth + 1 == depth),
              MobilePrevColumn:
                lastKnowActiveNode && lastKnowActiveNode?.depth >= depth,
            }"
          >
            <div
              *ngIf="column.depth > 0"
              class="Item Item--active Item--cursor"
              [flShowMobile]="true"
              [flMarginBottom]="'small'"
              [flMarginBottomTablet]="'xxsmall'"
              [flTrackingLabel]="'SeoNavbarBack'"
              tabindex="-1"
              (click)="setVisible(lastKnowActiveNode?.parent, true)"
            >
              <fl-icon
                class="Item-previousIndicator"
                [name]="'ui-chevron-left'"
                [color]="IconColor.INHERIT"
                [size]="IconSize.XSMALL"
              ></fl-icon>
              <fl-text
                [color]="FontColor.INHERIT"
                [flMarginRight]="'mid'"
              >
                {{ lastKnowActiveNode?.name }}
              </fl-text>
            </div>
            <div
              *ngFor="let parent of column.parentNodes; trackBy: trackByName"
              class="ChildNodesContainer"
              [ngClass]="{ 'ChildNodesContainer--visible': parent.active }"
            >
              <ng-container
                *ngFor="
                  let nodeItem of parent.nodes;
                  let isLast = last;
                  trackBy: trackByName
                "
              >
                <div
                  *ngIf="nodeItem.isParent"
                  class="Item Item--cursor"
                  [class.Item--active]="nodeItem.active"
                  [flTrackingLabel]="'SeoNavBar ' + nodeItem.name"
                  [flMarginBottom]="isLast ? 'none' : 'small'"
                  [flMarginBottomTablet]="isLast ? 'none' : 'xxsmall'"
                  tabindex="-1"
                  (mouseenter)="setVisible(nodeItem)"
                  (click)="setVisible(nodeItem)"
                >
                  <fl-text
                    [color]="FontColor.INHERIT"
                    [flMarginRight]="'mid'"
                  >
                    {{ nodeItem.name }}
                  </fl-text>
                  <fl-icon
                    [name]="'ui-chevron-right'"
                    [color]="IconColor.INHERIT"
                    [size]="IconSize.XSMALL"
                  ></fl-icon>
                </div>
                <div
                  *ngIf="!nodeItem.isParent"
                  class="Item"
                  [flMarginBottom]="isLast ? 'none' : 'small'"
                  [flMarginBottomTablet]="isLast ? 'none' : 'xxsmall'"
                >
                  <a
                    class="Item-link"
                    [flTrackingLabel]="'SeoNavBar ' + nodeItem.name"
                    [href]="nodeItem.relativeUrl"
                  >
                    {{ nodeItem.name }}
                  </a>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </fl-callout-content>
    </fl-callout>
  `,
  styleUrls: ['./mega-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MegaMenuComponent implements OnChanges {
  CalloutPlacement = CalloutPlacement;
  CalloutSize = CalloutSize;
  FontColor = FontColor;
  FontWeight = FontWeight;
  IconColor = IconColor;
  IconSize = IconSize;
  LinkColor = LinkColor;

  lastKnowActiveNode?: MenuNode;
  columns: readonly Column[];

  @Input() crawlable: boolean;
  @Input() treeData: SeoNavbarParent;
  @Output() isActiveTrigger = new EventEmitter<boolean>();

  constructor(
    private location: Location,
    private router: Router,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('treeData' in changes && this.treeData) {
      this.columns = [];
      this.arrangeByDepth();
    }
  }

  isSeoNavbarParent(
    result: SeoNavbarParent | SeoNavbarLink,
  ): result is SeoNavbarParent {
    return (result as SeoNavbarParent).nodes !== undefined;
  }

  convertToMenuMode(
    node: SeoNavbarParent | SeoNavbarLink,
    depth: number,
    parentMenu?: MenuNode,
  ): MenuNode {
    let childNodes: MenuNode[] = [];
    const newMenuNode: MenuNode = {
      name: node.name,
      relativeUrl: node.relativeUrl
        ? new URL(`${this.location.origin}${node.relativeUrl}`).toString()
        : undefined,
      parent: parentMenu,
      active: false,
      depth,
      isParent: this.isSeoNavbarParent(node),
    };

    if (this.isSeoNavbarParent(node)) {
      childNodes = node.nodes.map((value, index) =>
        this.convertToMenuMode(value, depth + 1, newMenuNode),
      );
    }
    newMenuNode.nodes = childNodes;

    return newMenuNode;
  }

  arrangeByDepth(): void {
    const topMostColumns = this.treeData.nodes.map(node =>
      this.convertToMenuMode(node, 0),
    ) as readonly MenuNode[];
    let parentNodes: readonly MenuNode[] = [
      {
        name: this.treeData.name,
        nodes: topMostColumns,
        active: true,
        depth: 0,
        isParent: true,
      },
    ];
    let depth = 0;
    while (parentNodes.length > 0) {
      this.columns = [
        ...this.columns,
        {
          depth,
          parentNodes,
        },
      ];

      parentNodes = this.columns[depth].parentNodes.reduce(
        (allNodes, parent) => [
          ...allNodes,
          ...(parent.nodes ? parent.nodes : []),
        ],
        [] as readonly MenuNode[],
      );
      depth++;
    }
  }

  trackByDepth(_: number, column: Column): number {
    return column.depth;
  }

  trackByName(_: number, node: MenuNode): string {
    return node.parent ? node.parent.name + node.name : node.name;
  }

  setVisible(node?: MenuNode, back = false): void {
    if (back || (node && node.nodes && node.nodes.length > 0)) {
      this.deactivateLastKnowActive();
      let currentNode: MenuNode | undefined = node;
      while (currentNode) {
        currentNode.active = true;
        currentNode = currentNode.parent;
      }
      this.lastKnowActiveNode = node;
    }
  }
  openCalloutHandler(): void {
    this.isActiveTrigger.emit(true);
  }
  closeCalloutHandler(): void {
    this.isActiveTrigger.emit(false);
    this.deactivateLastKnowActive();
  }

  deactivateLastKnowActive(): void {
    let currentNode: MenuNode | undefined = this.lastKnowActiveNode;
    while (currentNode) {
      currentNode.active = false;
      currentNode = currentNode.parent;
    }
    this.lastKnowActiveNode = undefined;
  }

  onClick(event: Event): void {
    event.preventDefault();
    const target = event.target as HTMLElement;
    const link = target.getAttribute('href');

    if (link) {
      const params: QueryParams = {};
      const url = new URL(link);
      url.searchParams.forEach((value, key) => {
        params[key] = value;
      });
      this.location.navigateByUrl(
        this.router.createUrlTree([url.pathname], {
          queryParams: params,
        }),
      );
    }
  }

  handleRootLink(): boolean {
    // Prevent navigation by root level link on touch devices
    // so sub-menus are displayed

    /* eslint-disable no-restricted-globals */
    const isNotTouch =
      typeof window !== 'undefined' &&
      typeof navigator !== 'undefined' &&
      !('ontouchstart' in window || navigator.maxTouchPoints > 0);

    return isNotTouch;
  }
}

interface Column {
  depth: number;
  parentNodes: readonly MenuNode[];
}

interface MenuNode {
  name: string;
  nodes?: readonly MenuNode[];
  relativeUrl?: string;
  parent?: MenuNode;
  active: boolean;
  depth: number;
  isParent: boolean;
}
